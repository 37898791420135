import { validate as validateCPF } from 'gerador-validador-cpf';
import { checkCreditCardType } from '@commons/helpers';

import moment from 'moment';

const validCPF = value => {
	let cpf = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;
	return cpf.test(value) && validateCPF( value );
}

const isValidMobilePhone = value => {
	return !!value.match(/([0-9]{2})\s([0-9]{5}-[0-9]{4})/gi);
}

const isStrongPassword = value => {
	return !!value.match(/[0-9]/g) && //Numbers
            !!value.match(/[A-Z]/g) && //Uppercase
            !!value.match(/[a-z]/g); //Lowercase
}

const isValidDateBR = value => {
	return moment(value, 'DD/MM/YYYY', true).isValid();
}

const isValidCreditCard = value => {
	const creditCardType = checkCreditCardType(value);
	const cardNumberLength = value.replace(/ /g,'').length;

	if (!creditCardType) {
		return false;
	}

	if (creditCardType.includes('american_express') && cardNumberLength == 15) {
		return true;
	}

	if (creditCardType.includes('diners') && cardNumberLength == 14) {
		return true;
	}

	return creditCardType && cardNumberLength == 16;
}

const isValidCardExpiration = value => {
	if (!value.includes('/')) {
		return false;
	}

	const slicedDate = value.split('/');

	if (slicedDate[0] < 1 || slicedDate[0] > 12) {
		return false;
	}

	const todayDate = new Date();
	const cardExpirationDate = new Date(slicedDate[1], slicedDate[0], 0);

	return cardExpirationDate.valueOf() > todayDate.valueOf();
}

export {
	validCPF,
	isValidMobilePhone,
	isStrongPassword,
	isValidDateBR,
	isValidCreditCard,
	isValidCardExpiration
}
